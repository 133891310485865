






















import { CarEngine } from '@/api/interfaces/carengine'
import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'

export default defineComponent({
  name: 'SelectSearchCarEngine',
  components: {
    SelectSearch: () => import('../m-select-search.vue'),
    CreateEditCarEngine: () => import('../dialog/createEditCarEngine.vue'),
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null,
    },
    carModel: {
      type: Object,
      required: false,
      default: () => null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      dialogCarEngine: false,
      carEngine: {} as CarEngine,
    }
  },
  computed: {
    ...mapState('carEngine', {
      carEngineList: 'list',
      isLoadingCarEngines: 'isLoading',
    }),
  },
  created () {
    this.loadEngine()
  },
  watch: {
    carModel () {
      this.loadEngine()
    },
  },
  methods: {
    ...mapActions('carEngine', {
      load: 'load',
      save: 'save',
    }),
    itemText (item: CarEngine) {
      // use this function instead of arrow function in item-text prop, workaround for https://github.com/vuetifyjs/vuetify/issues/11585
      return item.engine
    },
    loadEngine () {
      if (this.carModel?.id) this.load(this.carModel)
    },
    onInput (uri: string) {
      const carEngine = (this.carEngineList as Array<CarEngine>).find(
        x => x['@id'] === uri,
      )
      this.$emit('input', carEngine)
    },
    createEngine () {
      this.dialogCarEngine = !this.dialogCarEngine
    },
  },
})
